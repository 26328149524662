import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/home';
import Blog from './pages/blog';
import OqueeOptometria from './pages/o-que-e-optometria';
import OqueeMiopia from './pages/o-que-e-miopia';
import OqueeHipermetropia from './pages/o-que-e-hipermetropia';
import OqueePresbiopia from './pages/o-que-e-presbiopia';
import OqueeAmbliopia from './pages/o-que-e-ambliopia';
import OqueeDaltonismo from './pages/o-que-e-daltonismo';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/o-que-e-optometria" element={<OqueeOptometria />} />
          <Route path="/o-que-e-miopia" element={<OqueeMiopia />} />
          <Route path="/o-que-e-hipermetropia" element={<OqueeHipermetropia />} />
          <Route path="/o-que-e-presbiopia" element={<OqueePresbiopia />} />
          <Route path="/o-que-e-ambliopia" element={<OqueeAmbliopia />} />
          <Route path="/o-que-e-daltonismo" element={<OqueeDaltonismo />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
