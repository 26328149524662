import React from 'react';
import '../styles/blog.css';
import video from '../assets/o-que-e-optometria.mp4';

function OqueeOptometria ()  {
  return (

<div className="pages">
 <div className="pagesContainer">         
               
   <h2>O que é Optometria ?</h2>
   <video src={video} controls={true} autoPlay={true} muted playsInline width="100%" height="auto" >  </video>

    <p> A Optometria é uma ciência da saúde com base na física. A profissão existe no mundo há mais de cem anos, sendo praticada em mais de 130 países. <br/>
    O Optometrista é responsável pelo cuidado primário visual, profissional não médico, que se baseia em detectar e corrigir opticamente os defeitos refrativos, detectar e tratar as alterações motoras, identificar as alterações patológicas através do estudo de sinais e sintomas, encaminhando ao especialista quando necessário. <br/>
    A palavra optometria deriva etimologicamente do grego optometron, sendo esta decomposta em opto, que provém de opsis que significa “visão” e de metron que significa “medição”. <br/>
    A Optometria é uma ciência especializada no estudo da visão, especificamente nos cuidados primários da saúde visual.
    Aquele que exerce a optometria denomina-se Optometrista, tratando-se de profissional independente da área da saúde, com formação superior. <br/>
    Em Portugal existem também Optometristas diplomados, que estão habilitados a examinar e avaliar o sentido da visão, sendo um especialista em identificar e compensar alterações visuais de origem não patológica, melhorando o desempenho visual dos pacientes. <br/>
    O optometrista não utiliza qualquer medicamento ou técnica invasiva (exceto nos EUA e Inglaterra). Todos os equipamentos utilizados são de caráter observatório e direcionados para a avaliação quantitativa e qualitativa da visão.
    É treinado para reconhecer uma alteração visual de ordem patológica ocular ou sistêmica encaminhando, nesses casos, a um profissional da área médica, realizando assim o seu trabalho de prevenção. <br/>
    O Conselho Brasileiro de Óptica e Optometria – CBOO é uma entidade sem fins lucrativos e de caráter associativo, com sede em Brasília, Distrito Federal, pessoa jurídica de direito privado, instituição que congrega estatutariamente quinze (15) Conselhos Regionais de Óptica e Optometria, legalmente instalados nos estados brasileiros. <br/> Atualmente, o CBOO é filiado ao World Council of Optometry – WCO (Conselho Mundial de Optometria)</p>
    
 </div>
</div>

    )
}

export default OqueeOptometria;
