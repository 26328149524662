import React from 'react';
import '../styles/blog.css';
import { Link } from 'react-router-dom';


function Blog ()  {
  return (
    <div className="blog">
       <div className="blogContainer">           
        <div>        
          <h2>O que é Optometria</h2>
          <Link to='/o-que-e-optometria' target="_top"> 
          <img src="images/o-que-e-optometria.webp" width="60%" height="auto" alt="O que é Optometria"/> </Link> </div>   
          <div> 
          <h2>O que é Miopia?</h2>
          <Link to='/o-que-e-miopia' target="_top">
          <img src="images/o-que-e-miopia.webp" width="60%" height="auto" alt="O que é Miopia"/> </Link> </div>
          <div>        
          <h2>O que é Hipermetropia?</h2>
          <Link to='/o-que-e-hipermetropia' target="_top">
          <img src="images/o-que-e-hipermetropia.webp" width="60%" height="auto" alt="O que é Hipermetropia"/> </Link> </div>   
           <div> 
          <h2>O que é Presbiopia?</h2>
          <Link to='/o-que-e-presbiopia' target="_top">
          <img src="images/o-que-e-presbiopia.webp" width="60%" height="auto" alt="Landing Page e-book"/> </Link> </div>
          <div> 
          <h2>O que é Ambliopia ou olho preguiçoso?</h2>
          <Link to='/o-que-e-ambliopia'  target="_top" >
          <img src="images/o-que-e-ambliopia.webp" width="60%" height="auto" alt="e-book"/> </Link> </div>        
          <div> 
          <h2>O que é Daltonismo?</h2>
          <Link to='/o-que-e-daltonismo' target="_top">
          <img src="images/o-que-e-daltonismo.webp" width="60%" height="auto" alt="e-book" /> </Link> </div>
      </div>
      </div>
    
  )
}

export default Blog;
