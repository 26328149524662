import React from 'react';
import '../styles/blog.css';


function OqueeDaltonismo  () {
  return (
      
<div className="pages">
 <div className="pagesContainer">         
               
   <h2>O que é Daltonismo?</h2>
   <p>O daltonismo é uma deficiência da visão de cores, é a incapacidade de distinguir certos tons de cor. A visão colorida é possível devido a fotorreceptores (cones) na retina do olho. Esses cones possuem pigmentos sensíveis à luz que permitem o cérebro reconhecer as cores. <br/> Encontrado na mácula (a parte central da retina), cada cone é sensível à luz vermelha, verde ou azul (comprimentos de onda longos, médios ou curtos). Os cones reconhecem essas luzes com base em seus comprimentos de onda. Normalmente, os pigmentos dentro dos cones registram cores diferentes e enviam essa informação através do nervo óptico para o cérebro. Isso nos permite distinguir inúmeras tonalidades de cores. Mas se os cones não tiverem um ou mais pigmentos sensíveis à luz, não conseguiremos ver todas as cores. <br/> A maioria das pessoas com deficiência de visão de cores consegue ver cores.
   A forma mais comum de deficiência de cor é o vermelho-verde. Isso não significa que as pessoas com essa deficiência não consigam ver essas cores por completo, simplesmente têm mais dificuldade em diferenciá-las, o que pode depender da escuridão ou da clareza das cores. <br/>
   Outra forma de deficiência de cor é o azul-amarelo. Esta é uma forma mais rara e grave de perda de visão das cores do que apenas a deficiência de vermelho-verde, porque as pessoas com deficiência de azul-amarelo frequentemente também apresentam cegueira para o vermelho-verde. <br/>
   Em ambos os casos, as pessoas com deficiência de visão de cores geralmente veem áreas neutras ou cinzentas onde as cores deveriam aparecer. <br/>
   Pessoas com deficiência total de cores, uma condição chamada acromatopsia, só conseguem ver as coisas em preto e branco ou em tons de cinza. <br/>
   A deficiência de visão de cores pode variar de leve a grave, dependendo da causa. Afeta ambos os olhos se for hereditário e geralmente apenas um se for causado por lesão ou doença.
   Normalmente, a deficiência de cor é uma doença hereditária causada por um gene recessivo comum ligado ao X, que é transmitido de mãe para filho. <br/> Mas doenças ou lesões que danificam o nervo óptico ou a retina também podem causar perda de reconhecimento de cores.
   </p>
</div>
</div>

)
}

export default OqueeDaltonismo;