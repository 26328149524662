import React, { useState, useEffect, useCallback } from 'react';
import Logo from '../optocomLogo.png';
import { Link } from 'react-router-dom';
import '../styles/navbar.css';
import { NavLink } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { LuMenuSquare } from 'react-icons/lu';


function Navbar() {
  const [click, setClick] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hideNavbar, setHideNavbar] = useState(false);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => {
    setClick(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScroll = useCallback(() => {
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollPosition > scrollPosition) {
      setHideNavbar(true);
    } else {
      setHideNavbar(false);
    }
    setScrollPosition(currentScrollPosition);
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return ( <>
  <IconContext.Provider value={{ color: "#009399" }}>
    <nav className={`navbar ${hideNavbar ? 'hidden' : ''}`}>
      <div className="navbar-container container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={Logo} alt='Logo' />
          </Link>

    <div className="menu-icon" onClick={handleClick}>
      {click ? <FaTimes /> : <LuMenuSquare />}
    </div>

  <ul className={click ? "nav-menu active" : "nav-menu"}>
    <li className="nav-item">
     <NavLink to="/" className={({ isActive }) => "nav-links" + (isActive ? " activated" : "")
    } onClick={closeMobileMenu} > INÍCIO
      </NavLink></li>
    
    <li className="nav-item">
      <NavLink to="/blog" className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
    } onClick={closeMobileMenu}> BLOG
      </NavLink></li>
    
    <li className="nav-item">
    <a href="/#agendeBar" className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
    } onClick={closeMobileMenu}> AGENDAMENTO </a> 
        </li>   
    <li className="nav-item">
    <a href="/#examesBar" className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
    } onClick={closeMobileMenu}> EXAMES </a> 
        </li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;