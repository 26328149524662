import React from 'react';
import '../styles/blog.css';


function OqueeAmbliopia  () {
  return (
      
<div className="pages">
 <div className="pagesContainer">         
               
   <h2>O que é ambliopia ou olho preguiçoso?</h2>
   <p>A ambliopia também conhecida como olho preguiçoso é um desvio ou desalinhamento de um olho ou ambos os olhos devido ao desenvolvimento anormal da visão na infância.
É a principal causa de perda de visão entre as crianças. <br/> A perda de visão ocorre porque as vias que transmitem sinal entre o cérebro e os olhos não são adequadamente estimuladas. O cérebro vê apenas imagens borradas com o olho amblíope. Como resultado, o cérebro favorece um olho, geralmente devido à falta de visão no outro olho. <br/>
Uma das causas mais comuns é o estrabismo. Quando os músculos dos olhos não conseguem alinhar os olhos de forma adequada, especialmente durante o desenvolvimento, o cérebro recebe uma imagem diferente de cada olho. O cérebro resolve esta confusão ignorando sistematicamente uma das imagens. Isto faz com que um olho não seja usado e com que a área correspondente do cérebro fique subdesenvolvida. <br/>
Outra causa comum é um erro refrativo (miopia/hipermetropia ou astigmatismo) que seja maior num dos olhos. Isto também provoca uma informação descombinada, sendo que a precedência de um dos olhos faz com que o cérebro ignore a informação do outro. <br/>
Tudo o que crie este tipo de desequilíbrio visual pode também provocar ambliopia. Isto inclui catarata na infância, lentes turvas, diferenças de forma ou de tamanho e outras anomalias anatômicas ou estruturais.
   </p>
</div>
</div>

)
}

export default OqueeAmbliopia;