import React from 'react';
import '../styles/blog.css';


function OqueeMiopia  () {
  return (
      
<div className="pages">
 <div className="pagesContainer">         
               
   <h2>O que é Miopia</h2>
   <p>A miopia é um erro refrativo muito comum que geralmente pode ser corrigido com óculos ou lentes de contato. <br/>
Se você é míope, sua visão fica nítida quando você olha para as coisas de perto, mas os objetos que estão mais distantes ficam fora de foco ou embaçados.<br/>
Quais são os sintomas da miopia? A miopia faz com que objetos mais distantes pareçam desfocados ou embaçados, o que pode prejudicar sua visão quando você estiver dirigindo, praticando esportes ou tentando reconhecer pessoas e objetos à distância. Isso pode causar dores de cabeça, cansaço visual e fadiga. <br/>
O que causa a miopia? A miopia ocorre geralmente porque seu olho é muito longo (diâmetro ântero-posterior), ou a córnea ou o cristalino desviam demais a luz.
A luz é focada na frente da retina, fazendo com que objetos distantes pareçam borrados.<br/>
Geralmente a miopia começa na infância ou no início da adolescência, ma você pode tornar-se míope em qualquer idade.
É mais provável que você a desenvolva se um ou ambos os pais também forem míopes. <br/>
Passar muito tempo focando os olhos em objetos próximos, como leitura, escrita e dispositivos portáteis (telefones e tablets), bem como computadores, também pode aumentar o risco de desenvolver miopia.
Passar tempo brincando ao ar livre quando criança pode ajudar a reduzir o aparecimento de miopia; estudos indicam que níveis mais elevados de luz natural são benéficos. </p>

</div>
</div>
    
  )
}

export default OqueeMiopia;
