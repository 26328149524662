import React from 'react';
import '../styles/blog.css';


function OqueeHipermetropia  () {
  return (
      
<div className="pages">
 <div className="pagesContainer">         
               
   <h2>O que é Hipermetropia</h2>
   <p> A Hipermetropia é um erro refrativo que faz com que a imagem seja focada atrás da Retina. Se você é hipermetrope, vê os objetos claramente à distância, mas tem dificuldade em focar nas coisas proxímas, principalmente a leitura de textos.<br/>
   O hipermetrope tem a capacidade refrativa dos olho alterada em relação aos olhos com visão normal.
   Sintomas: <br/> O principal sintoma da hipermetropia é a visão embaçada mais para perto, mas também podem existir queixas de dores de cabeça ou cansaço ocular, sensação de peso ao redor dos olhos, ardor, vermelhidão conjuntival e lacrimejamento ocular. <br/>
   Hipermetropia pode ser corrigida por óculos e lentes de Contato.

   </p>
</div>
</div>

)
}

export default OqueeHipermetropia;