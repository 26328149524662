import React from 'react';
import '../styles/blog.css';


function OqueePresbiopia  () {
  return (
      
<div className="pages">
 <div className="pagesContainer">         
               
   <h2>O que é Presbiopia</h2>
   <p> A presbiopia é causada pelo envelhecimento natural do olho, com o tempo, o cristalino que está dentro do olho se torna menos flexível, mais espesso e enrijece, perdendo assim a sua principal caracterista a elasticidade e sua capacidade de focar objetos próximos diminui gradativamente.
   Sintomas: <br/>
   No início da presbiopia, você pode perceber que os ajustes de foco são mais lentos ou exigem mais esforço.
   <br/> Algumas pessoas vão notar mais em ambientes com pouca luminosidade ou se estiverem cansados.
   Os sintomas e problemas variam, alguns podem começar a sentir cansaço visual ou dores de cabeça devido a dificuldade de focar em objetos. Se você sentir isso, entre em contato com seu optometrista ou oftalmologista para fazer uma avaliação.
   </p>
</div>
</div>

)
}

export default OqueePresbiopia;